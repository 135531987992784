import React, { useState, createContext, useContext } from 'react';
import styles from './index.module.less';

// type AccordionContextType = {
//   openItem: string | null;
//   toggleItem: (value: string) => void;
// };

// const AccordionContext = createContext<AccordionContextType | undefined>(undefined);

export const Accordion: React.FC<{ children: React.ReactNode; defaultValue?: string }> = ({ children, defaultValue }) => {
  const [openItem, setOpenItem] = useState<string | null>(defaultValue || null);

  const toggleItem = (value: string) => {
    setOpenItem(prevOpenItem => prevOpenItem === value ? null : value);
  };

  return (
    // <AccordionContext.Provider value={{ openItem, toggleItem }}>
    <div className={styles.accordion}>{children}</div>
    // </AccordionContext.Provider>
  );
};

export const AccordionItem: React.FC<{ children: React.ReactNode; value: string; id?: string; onClick?: () => void }> = ({ children, value, id, onClick }) => {
  return <div id={id} className={styles.accordionItem} onClick={onClick}>{children}</div>;
};

export const AccordionTrigger: React.FC<{ children: React.ReactNode; value: string; isOpen?: boolean }> = ({ children, value, isOpen }) => {
  // const context = useContext(AccordionContext);
  // if (!context) throw new Error('AccordionTrigger must be used within an Accordion');

  // const { openItem, toggleItem } = context;
  // const isOpen = openItem === value;

  return (
    <button className={styles.accordionTrigger}>
      {children}
      <span className={`${styles.accordionIcon} ${isOpen ? styles.accordionIconMinus : styles.accordionIconPlus}`}>
        {isOpen ? '-' : '+'}
      </span>
    </button>
  );
};

export const AccordionContent: React.FC<{ children: React.ReactNode; value: string; isOpen?: boolean }> = ({ children, value, isOpen }) => {
  // const context = useContext(AccordionContext);
  // if (!context) throw new Error('AccordionContent must be used within an Accordion');

  // const { openItem } = context;
  // const isOpen = openItem === value;

  if (!isOpen) return null;

  return <div className={styles.accordionContent}>{children}</div>;
};

