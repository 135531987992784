import React, { useMemo, useState } from 'react';
import Image from 'next/image';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from './AccordionUI';
import styles from './index.module.less';
import { commonImgPath } from '@/shared/app-common';
import { imgUrl } from '@/shared/utils';
import { useRouter } from 'next/router';
import { useWindowSize } from 'react-use';

const InstantKnowHelpUI: React.FC<{ onTryNowClick?: () => void }> = ({ onTryNowClick }) => {
  const router = useRouter();
  const { width } = useWindowSize()

  const listData = useMemo(() => {
    return [
      {
        title: 'Website Content Changes',
        subTitle: `Monitor website changes for timely insights, easily receive notifications, and visualize trends to enhance your team's efficiency`,
        picture: commonImgPath + '/home-help-icon-1.webp',
        id: width > 768 ? 'help' : 'help-1',
      },
      {
        title: 'Instant Email Notifications',
        subTitle: `Get instant email notifications for real-time updates and alerts, ensuring you stay informed and make effective decisions quickly`,
        picture: commonImgPath + '/home-help-icon-2.webp',
        id: width > 768 ? 'help' : 'help-2',
      },
      {
        title: 'Regional Monitoring Service',
        subTitle: `Easily monitor specific areas for targeted insights, enabling flexible strategies that deliver precise information where it matters most`,
        picture: commonImgPath + '/home-help-icon-3.webp',
        id: width > 768 ? 'help' : 'help-3',
      },
      {
        title: 'AI Analysis and Summarization',
        subTitle: `Leverage AI analysis to extract key insights, enabling a quick understanding of data changes for efficient and targeted analysis`,
        picture: commonImgPath + '/home-help-icon-4.webp',
        id: width > 768 ? 'help' : 'help-4',
      },
      {
        title: 'Powerful Database Integration',
        subTitle: `Leverage robust database tools for tracking and analyzing website changes efficiently`,
        picture: commonImgPath + '/home-help-icon-5.webp',
        id: width > 768 ? 'help' : 'help-5',
      },
    ]
  }, [])

  const [currentData, setCurrentData] = useState(listData?.at(0))

  return (
    <div className={styles.landingPage} id='help'>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h2 className={styles.title}>InstantKnow helps you get what you want</h2>
            <p className={styles.subtitle}>
              <span>{`More Than Just Website Monitoring`}</span>
            </p>
          </div>

          <Accordion defaultValue={currentData?.title}>
            {listData.map((item) => (
              <AccordionItem
                key={item.title}
                id={item.id}
                value={item.title}
                onClick={() => {
                  router.push(`/#${item.id}`)
                  setCurrentData(item)
                }} >
                <AccordionTrigger value={item.title} isOpen={currentData?.title === item.title}>
                  <span className={styles.accordionTitle}>{item.title}</span>
                </AccordionTrigger>
                <AccordionContent value={item.subTitle} isOpen={currentData?.title === item.title}>
                  <p className={styles.accordionDescription}>
                    {item.subTitle}
                  </p>
                  <button className={`${styles.button} ${styles.buttonPrimary}`} onClick={onTryNowClick}>Try Now &gt;</button>
                </AccordionContent>

                {(currentData?.title === item.title) && <div className={styles.phoneImageContainer}>
                  <Image
                    src={imgUrl(currentData?.picture, 'big')}
                    alt="InstantKnow Dashboard Preview"
                    layout="fill"
                    objectFit="contain"
                    className={styles.image}
                    loading='lazy'
                  />
                </div>}
              </AccordionItem>
            ))}
          </Accordion>
        </div>

        <div className={styles.imageContainer}>
          <Image
            src={imgUrl(currentData?.picture, 'big')}
            alt="InstantKnow Dashboard Preview"
            layout="fill"
            objectFit="contain"
            className={styles.image}
            loading='lazy'
          />
        </div>
      </div>
    </div>
  );
};

export default InstantKnowHelpUI;

